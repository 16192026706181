<template>
    <div>

        <MemberForm
            :key="memberFormComponentKey"
            ref="memberForm"
            :membre="selectedMember"
            :processing="memberFormProcessing"
            :visible="memberFormModalVisible"
            @cancel="hideDecisionForm"
            @close="hideDecisionForm"
            @submit="handleMemberFormSubmission"
        />
       <EventForm
           :key="eventFormComponentKey"
           ref="eventForm"
           :evenement="selectedEvent"
           :processing="eventFormProcessing"
           :visible="eventFormModalVisible"
           @cancel="hideEventForm"
           @close="hideEventForm"
           @submit="handleEventFormSubmission"
        />
       <DocumentForm
           :key="documentFormComponentKey"
           ref="documentForm"
           :document="selectedDocument"
           :processing="documentFormProcessing"
           :visible="documentFormModalVisible"
           @cancel="hideDocumentForm"
           @close="hideDocumentForm"
           @submit="handleDocumentFormSubmission"
        />
        <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="Messages communications ">
                <a-spin :spinning="processing">
                    <a-form
                        id="add_form_form"
                        :form="form"
                        :layout="myFormLayout"
                        name="add_form_form"
                    >
                        <a-row align="middle" justify="space-around" type="flex">
                            <a-col :lg="14" :span="13" :xs="24">
                                <p>Logo :</p>
                                <UploaderWithPreview :action="organisationLogoUploadUrl(accueil.id)"
                                                     :bearer="getAuthorizationToken()"
                                                     :img-url="accueil.logo_url"
                                                     :record="accueil"
                                                     :show-uploaded-img="showUploadedImg()"
                                                     name="file"
                                                     @delete_file="deleteFile($event)"
                                                     @upload_success="onUploadSuccess()"/>
                            </a-col>
                            <a-col :lg="14" :span="13" :xs="24">
                                <a-form-item
                                    label="Accueil :"
                                >
                                    <vue-editor
                                        v-model="accueil.message_accueil"
                                    ></vue-editor>
                                </a-form-item>
                            </a-col>
                            <a-col :lg="14" :span="13" :xs="24">
                                <a-form-item
                                    label="A propos :"
                                >
                                    <vue-editor
                                        v-model="accueil.a_propos"
                                    ></vue-editor>
                                </a-form-item>
                            </a-col>
                            <a-col :lg="14" :span="13" :xs="24">
                                <a-form-item
                                    label="Horaires :"
                                >
                                    <vue-editor
                                        v-model="accueil.horaires"
                                    ></vue-editor>
                                </a-form-item>
                            </a-col>
                            <a-col :lg="14" :span="13" :xs="24">
                                <a-form-item
                                    label="Mot du président :"
                                >
                                    <vue-editor
                                        v-model="accueil.mot_de_president"
                                    ></vue-editor>
                                </a-form-item>
                            </a-col>
                                <a-col :lg="14" :span="13" :xs="24">
                                <a-form-item
                                    label="Afficher les membres:"
                                >
                                    <a-switch
                                    v-decorator="[
                                        'display_members',
                                        {
                                        rules: [
                                            {
                                            required: false,
                                            message: 'Ce champ est bligatoire!',
                                            },
                                        ],
                                        },
                                    ]"
                                    :checked="accueil.display_members"
                                    @change="onMembersDisplayToggle"
                                    />
                                </a-form-item>
                                </a-col>
                                  <a-col :lg="14" :span="13" :xs="24">
                                <a-form-item
                                    label="Afficher A propos :"
                                >
                                    <a-switch
                                    v-decorator="[
                                        'display_about',
                                        {
                                        rules: [
                                            {
                                            required: false,
                                            message: 'Ce champ est bligatoire!',
                                            },
                                        ],
                                        },
                                    ]"
                                    :checked="accueil.display_about"
                                    @change="onAboutDisplayToggle"
                                    />
                                </a-form-item>
                                </a-col>
                                  <a-col :lg="14" :span="13" :xs="24">
                                <a-form-item
                                    label="Afficher les evenements:"
                                >
                                    <a-switch
                                    v-decorator="[
                                        'display_events',
                                        {
                                        rules: [
                                            {
                                            required: false,
                                            message: 'Ce champ est bligatoire!',
                                            },
                                        ],
                                        },
                                    ]"
                                    :checked="accueil.display_events"
                                    @change="onEventsDisplayToggle"
                                    />
                                </a-form-item>
                                </a-col>
                                  <a-col :lg="14" :span="13" :xs="24">
                                <a-form-item
                                    label="Afficher les documents:"
                                >
                                    <a-switch
                                    v-decorator="[
                                        'display_documents',
                                        {
                                        rules: [
                                            {
                                            required: false,
                                            message: 'Ce champ est bligatoire!',
                                            },
                                        ],
                                        },
                                    ]"
                                    :checked="accueil.display_documents"
                                    @change="onDocumentsDisplayToggle"
                                    />
                                </a-form-item>
                                </a-col>
                        </a-row>
                        <a-row>
                            <a-col :offset="2" :span="2">
                                <a-form-item v-bind="tailFormItemLayout">
                                    <a-button
                                        :loading="processing"
                                        htmlType="submit"
                                        type="primary"
                                        @click="handleMessageCommunicationsUpdate"
                                    >Sauvergarder
                                    </a-button>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-form>
                </a-spin>
            </a-tab-pane>
            <a-tab-pane key="2" force-render tab="Membres ">
                <a-row justify="start" type="flex">
                    <a-col>
                        <a-button
                            class="editable-add-btn mb-2"
                            type="primary"
                            @click="addNewMember"
                        >Ajouter un membre
                        </a-button
                        >
                    </a-col>
                </a-row>
                <a-table
                    v-if="membres.length"
                    :columns="membersColumns"
                    :dataSource="membres"
                    bordered
                >
                    <template slot="row_index" slot-scope="text, record, index">
                        <p>{{ index + 1 }}</p>
                    </template>
                    <template slot="full_name" slot-scope="text, record">
                        <a-avatar
                            v-if="record.photo"
                            slot="avatar"
                            :src="record.photo_url"
                        />
                        <a-avatar v-else>{{ record.full_name[0] }}</a-avatar>
                        <span style="margin-left: 1rem;">{{ record.full_name }}</span>
                    </template>
                    <template slot="upload" slot-scope="text, record">
                        <!-- _________________________VISUALIZE -->
                        <a-tooltip v-if="record.photo" placement="top">
                            <template slot="title">
                                <span>Visualiser</span>
                            </template>
                            <a-icon
                                class="action_icons"
                                type="search"
                                @click="previewDocument(record.photo)"
                            />
                        </a-tooltip>
                        <Uploader :action="uploadUrl(record.id)" :bearer="getAuthorizationToken()" :record="record"
                                  name="file"
                                  @delete_file="deleteFile($event)"
                                  @upload_success="onUploadSuccess()"/>
                    </template>
                    <template slot="operation" slot-scope="text, record">

                        <a-popconfirm
                            title="Etes-vous sûr de vouloir supprimer cette ligne ?"
                            @confirm="() => onMemberDelete(record.id)"
                        >
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Suppression</span>
                                </template>
                                <a-icon
                                    class="action_icons"
                                    theme="twoTone"
                                    twoToneColor="#eb2f96"
                                    type="delete"
                                />
                            </a-tooltip>
                        </a-popconfirm>
                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Edition</span>
                            </template>
                            <a-icon
                                class="action_icons"
                                theme="twoTone"
                                twoToneColor="#52c41a"
                                type="edit"
                                v-on:click="handleMemberEdit(record)"
                            />
                        </a-tooltip>
                    </template>
                    <template slot="etat" slot-scope="text, record">
                        {{ Number(record.etat) === VISIBLE ? 'Visible' : 'Cachée' }}
                    </template>
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="3" tab="Evenements">
                <a-row justify="start" type="flex">
                    <a-col>
                        <a-button
                            class="editable-add-btn mb-2"
                            type="primary"
                            @click="addNewEvent"
                        >Ajouter un evenement
                        </a-button
                        >
                    </a-col>
                </a-row>
                <a-table
                    v-if="events.length"
                    :columns="eventsColumns"
                    :dataSource="events"
                    bordered
                >
                    <template slot="row_index" slot-scope="text, record, index">
                        <p>{{ index + 1 }}</p>
                    </template>

                    <template slot="upload" slot-scope="text, record" type="flex">
                        <!-- _________________________VISUALIZE -->
                        <a-col v-for="(image, idx) in record.logo_url" v-key="image">
                            <a-row>
                                <a-tooltip placement="top">
                                    <template slot="title">
                                        <span>Visualiser</span>
                                    </template>
                                    <a-icon class="action_icons" type="search" @click="previewDocument(image)" />
                                </a-tooltip>
                                <a-popconfirm title="Etes-vous sûr de vouloir supprimer cette photo ?"
                                    @confirm="() => onImageDelete(record.id, idx)">
                                    <a-tooltip placement="top">
                                        <template slot="title">
                                            <span>Suppression</span>
                                        </template>
                                        <a-icon class="action_icons" theme="twoTone" twoToneColor="#eb2f96" type="delete" />
                                    </a-tooltip>
                                </a-popconfirm>
                            </a-row>
                        </a-col>
                        <Uploader :action="eventLogoUploadUrl(record.id)" :bearer="getAuthorizationToken()" :record="record" name="file"
                            @delete_file="deleteFile($event)" @upload_success="onUploadSuccess()" />
                    </template>
                    <template slot="operation" slot-scope="text, record">

                        <a-popconfirm
                            title="Etes-vous sûr de vouloir supprimer cette ligne ?"
                            @confirm="() => onEventDelete(record.id)"
                        >
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Suppression</span>
                                </template>
                                <a-icon
                                    class="action_icons"
                                    theme="twoTone"
                                    twoToneColor="#eb2f96"
                                    type="delete"
                                />
                            </a-tooltip>
                        </a-popconfirm>
                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Edition</span>
                            </template>
                            <a-icon
                                class="action_icons"
                                theme="twoTone"
                                twoToneColor="#52c41a"
                                type="edit"
                                v-on:click="handleEventEdit(record)"
                            />
                        </a-tooltip>
                    </template>
                    <template slot="etat" slot-scope="text, record">
                        {{ Number(record.etat) === VISIBLE ? 'Visible' : 'Cachée' }}
                    </template>
                    <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
                        {{ record.contenu }}
                    </div>
                </a-table>
            </a-tab-pane>
            <a-tab-pane key="4" tab="Contacts">
              <a-spin :spinning="contactDataProcessing">    
               <a-row justify="start" type="flex">
                    <a-col>
                        <a-button
                            class="editable-add-btn mb-2"
                            type="primary"
                            @click="getContacts()"
                        >Actualiser
                        </a-button>
                    </a-col>
                </a-row>
                <a-table
                    :columns="contactsColumns"
                    :dataSource="contacts"
                    bordered
                    :expandRowByClick="true"
                    @expand="onContactRowExpand"
                >
                    <template slot="row_index" slot-scope="text, record, index">
                      <a-badge v-if="isNewContact(record)" status="processing" />
                        {{ index + 1 }}
                    </template>
                    <template slot="etat" slot-scope="text, record">
                        {{ Number(record.etat) === VISIBLE ? 'Visible' : 'Cachée' }}
                    </template>
                    <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
                        {{ record.message }}
                    </div>
                </a-table>
              </a-spin>
            </a-tab-pane>
            <a-tab-pane key="5" tab="Documents">
               
               <a-row justify="start" type="flex">
                    <a-col>
                        <a-button
                            class="editable-add-btn mb-2"
                            type="primary"
                            @click="showDocumentForm"
                        >Ajouter
                        </a-button>
                    </a-col>
                </a-row>
                <a-table
                    :columns="documentsColumns"
                    :dataSource="documents"
                    bordered
                >
                    <template slot="row_index" slot-scope="text, record, index">
                        {{ index + 1 }}
                    </template>
                    <template slot="etat" slot-scope="text, record">
                        {{ Number(record.etat) === VISIBLE ? 'Visible' : 'Cachée' }}
                    </template>
                     <template slot="date" slot-scope="text, record">
                        {{ date(record.created_at) }}
                    </template>
                    <template slot="upload" slot-scope="text, record">
                        <!-- _________________________VISUALIZE -->
                        <a-tooltip v-if="record.file_name" placement="top">
                            <template slot="title">
                                <span>Visualiser</span>
                            </template>
                            <a-icon
                                class="action_icons"
                                type="search"
                                @click="previewDocument(record.file_url)"
                            />
                        </a-tooltip>
                        <Uploader :action="documentToDownloadUploadUrl(record.id)" :bearer="getAuthorizationToken()"
                                  :record="record"
                                  name="file"
                                  @delete_file="deleteFile($event)"
                                  @upload_success="onDocumentsUploadSuccess()"/>
                    </template>
                    <template slot="operation" slot-scope="text, record">
                        <a-popconfirm
                            title="Etes-vous sûr de vouloir supprimer cette ligne ?"
                            @confirm="() => onDocumentDelete(record.id)"
                        >
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Suppression</span>
                                </template>
                                <a-icon
                                    class="action_icons"
                                    theme="twoTone"
                                    twoToneColor="#eb2f96"
                                    type="delete"
                                />
                            </a-tooltip>
                        </a-popconfirm>
                        <a-tooltip placement="top">
                            <template slot="title">
                                <span>Edition</span>
                            </template>
                            <a-icon
                                class="action_icons"
                                theme="twoTone"
                                twoToneColor="#52c41a"
                                type="edit"
                                v-on:click="handleDocumentEdit(record)"
                            />
                        </a-tooltip>
                    </template>
                </a-table>
           
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {VueEditor} from "vue2-editor";
import MemberForm from "./MemberForm";
import DocumentForm from "./DocumentForm";
import EventForm from "./EventForm";
import Uploader from "@/components/common/Uploader";
import UploaderWithPreview from "@/components/common/UploaderWithPreview";

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 12,
            offset: 8,
        },
    },
};
const membersColumns = [
    {
        title: "N°",
        scopedSlots: {customRender: "row_index"},
    },
    {
        title: "Nom & Prénom",
        scopedSlots: {customRender: "full_name"}
    },
    {
        title: "Grade",
        dataIndex: "grade",
    },
    {
        title: "Visiblité",
        scopedSlots: {customRender: "etat"},
    },
    {
        title: "Photos",
        scopedSlots: {customRender: "upload"},
    },
    {
        title: "Actions",
        scopedSlots: {customRender: "operation"},
    }
];
const eventsColumns = [
    {
        title: "N°",
        scopedSlots: {customRender: "row_index"},
    },
    {
        title: "Titre",
        dataIndex: "titre"
    },


    {
        title: "Dt. Evenement",
        dataIndex: "dt_evenement"

    },
    {
        title: "Visibilité",
        scopedSlots: {customRender: "etat"},
    },
    {
        title: "Photo",
        scopedSlots: {customRender: "upload"},

    },
    {
        title: "Actions",
        scopedSlots: {customRender: "operation"},
    }
];
const contactsColumns = [
    {
        title: "N°",
        scopedSlots: {customRender: "row_index"},
    },
    {
        title: "Nom & Prénom",
        dataIndex: "full_name"
    },
    {
        title: "Sujet",
        dataIndex: "subject"

    },
    {
        title: "Email",
        dataIndex: "email",
    }
];
const documentsColumns = [
    {
        title: "N°",
        scopedSlots: {customRender: "row_index"},
    },
    {
        title: "Titre",
        dataIndex: "titre"
    },
    {
        title: "Dt. Création",
        scopedSlots: {customRender: "date"}

    },
    {
        title: "Document",
        scopedSlots: {customRender: "upload"}
    },
    {
        title: "Etat",
        scopedSlots: {customRender: "etat"},
    },
    {
        title: "Operation",
        scopedSlots: {customRender: "operation"}
    },
];
const VISIBLE = 2;

export default {
    name: "PageAccueilIndex",
    beforeCreate() {
        this.form = this.$form.createForm(this);
    },
    created() {
        this.fetchData()
    },
    components: {
        VueEditor,
        MemberForm,
        EventForm,
        DocumentForm,
        Uploader,
        UploaderWithPreview
    },
    data() {
        return {
            membersColumns,
            eventsColumns,
            contactsColumns,
            documentsColumns,
            VISIBLE,
            tailFormItemLayout,
            processing: false,
            contactDataProcessing: false,
            documentsDataProcessing: false,
            myFormLayout: "vertical",
            memberFormProcessing: false,
            memberFormModalVisible: false,
            memberFormComponentKey: 0,
            selectedMember: null,
            eventFormProcessing: false,
            eventFormModalVisible: false,
            eventFormComponentKey: 0,
            selectedEvent: null,
            documentFormProcessing: false,
            documentFormModalVisible: false,
            documentFormComponentKey: 0,
            selectedDocument: null,
        };
    },
    computed: {
        ...mapState({
            accueil: (state) => state.accueil.accueil.accueil,
            membres: (state) => state.membres.membres,
            events: (state) => state.events.events,
            documents: (state) => state.documents.documents,
            contacts: (state) => state.contacts.contacts,
            base_url: (state) => state.auth.base_url
        }),
    },
    methods: {
        onMembersDisplayToggle(checked) {
          this.accueil.display_members = checked
        },
        onDocumentsDisplayToggle(checked) {
          this.accueil.display_documents = checked
        },
        onEventsDisplayToggle(checked) {
          this.accueil.display_events = checked
        },
        onAboutDisplayToggle(checked) {
          this.accueil.display_about = checked
        },
        onContactRowExpand(row, contact){
            if(this.isNewContact(contact)){
            this.markContactAsRead(contact.id)
                .then(() => this.fetchContacts())
            }
        },
        onUploadSuccess() {
            this.fetchData()
        },
        onDocumentsUploadSuccess() {
            this.fetchDocuments()
        },
        isNewContact(contact){
            return !contact.read_at;
        },
        fetchData() {
            this.fetchMembres()
            this.fetchAccueil()
            this.fetchEvents()
            this.fetchContacts()
            this.fetchDocuments()
        },
        deleteFile(id) {
            // eslint-disable-next-line no-console
            console.warn('Deleting ... '.id)
        },
        showUploadedImg() {
            return !!this.accueil.logo;
        },
        uploadUrl(id) {
            return `${this.base_url}/admin/upload-members-image/${id}`;
        },
        organisationLogoUploadUrl(id) {
            return `${this.base_url}/admin/upload-accueil-logo/${id}`;
        },
        eventLogoUploadUrl(id) {
            return `${this.base_url}/admin/upload-event-logo/${id}`;
        },
         documentToDownloadUploadUrl(id) {
            return `${this.base_url}/admin/upload-document-to-download/${id}`;
        },
        addNewMember() {
            this.showMemberForm()
        },
        handleMemberFormSubmission() {
            const form = this.$refs["memberForm"].form

            form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.startMemberFormProcessing();
                    if (this.selectedMember) {
                        this.updateMembre({...this.selectedMember, ...values})
                            .then(() => {
                                this.fetchMembres();
                                this.hideDecisionForm();
                            })
                            .finally(() => this.stopMemberFormProcessing());
                        return
                    }
                    this.saveNewMember({...values})
                        .then(() => {
                            this.fetchMembres();
                            this.hideDecisionForm();
                        })
                        .finally(() => this.stopMemberFormProcessing());
                }
            });
        },
        handleEventFormSubmission() {
            const form = this.$refs["eventForm"].form

            form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.startEventFormProcessing();
                    if (this.selectedEvent) {
                        this.updateEvent({...this.selectedEvent, ...values})
                            .then(() => {
                                this.fetchEvents();
                                this.hideEventForm();
                            })
                            .finally(() => this.stopEventFormProcessing());
                        return
                    }
                    this.formatFields(values, values, ["dt_evenement"]);

                    this.saveNewEvent({...values})
                        .then(() => {
                            this.fetchEvents();
                            this.hideEventForm();
                        })
                        .finally(() => this.stopEventFormProcessing());
                }
            });
        },
        handleDocumentFormSubmission() {
            const form = this.$refs["documentForm"].form

            form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.startDocumentFormProcessing();
                    if (this.selectedDocument) {
                        this.updateDocument({...this.selectedDocument, ...values})
                            .then(() => {
                                this.fetchDocuments();
                                this.hideDocumentForm();
                            })
                            .finally(() => this.stopDocumentFormProcessing());
                        return
                    }

                    this.saveDocument({...values})
                        .then(() => {
                            this.fetchDocuments();
                            this.hideDocumentForm();
                        })
                        .finally(() => this.stopDocumentFormProcessing());
                }
            });
        },
        handleMemberEdit(membre) {
            this.selectedMember = membre
            this.genererateNewFormMemberEditorKey()
            this.showMemberForm()
        },
        handleEventEdit(event) {
            this.selectedEvent = event
            this.genererateNewEventFormEditorKey()
            this.showEventForm()
        },
        handleDocumentEdit(document) {
            this.selectedDocument = document
            this.genererateNewDocumentFormEditorKey()
            this.showDocumentForm()
        },
        handleMessageCommunicationsUpdate() {
            this.startProcessing();
            this.update({...this.accueil})
                .then(() => {
                    this.fetchAccueil()
                    this.$_showSuccessMessage()
                })
                .catch(() => this.$_throwAnError())
                .finally(() => this.stopProcessing())
        },
        onMemberDelete(key) {
            this.removeMember(key).then(() => this.fetchMembres())
        },
        onEventDelete(key) {
            this.removeEvent(key).then(() => this.fetchEvents())
        },
        onImageDelete(event_id, image_idx) {
            this.removeImage({ id: event_id, image_idx }).then(() => this.fetchEvents())
        },
        onDocumentDelete(key){
            this.removeDocument(key).then(() => this.fetchDocuments())
        },
        genererateNewFormMemberEditorKey() {
            this.memberFormComponentKey = this.getRandomInt()
        },
        showMemberForm() {
            this.memberFormModalVisible = true
        },
        hideDecisionForm() {
            this.memberFormModalVisible = false
            this.selectedMember = null
        },
        startMemberFormProcessing() {
            this.memberFormProcessing = true
        },
        stopMemberFormProcessing() {
            this.memberFormProcessing = false
        },
        sartContactDataProcessing() {
            this.contactDataProcessing = true
        },
        stopContactDataProcessing() {
            this.contactDataProcessing = false
        },
        addNewEvent() {
            this.showEventForm()
        },
        genererateNewEventFormEditorKey() {
            this.eventFormComponentKey = this.getRandomInt();
        },
        genererateNewDocumentFormEditorKey() {
            this.documentFormComponentKey = this.getRandomInt();
        },
        showEventForm() {
            this.genererateNewEventFormEditorKey()
            this.eventFormModalVisible = true
        },
        hideEventForm() {
            this.eventFormModalVisible = false
            this.selectedEvent = null
            this.stopEventFormProcessing()
        },
        showDocumentForm() {
            this.genererateNewDocumentFormEditorKey()
            this.documentFormModalVisible = true
        },
        hideDocumentForm() {
            this.documentFormModalVisible = false
            this.selectedDocument = null
            this.stopDocumentFormProcessing()
        },
        getData() {
            this.startProcessing();
            this.fetchData().then(() => this.stopProcessing());
        },
        getContacts() {
            this.sartContactDataProcessing()
            this.fetchContacts()
                .then(() => this.stopContactDataProcessing())
        },
        startProcessing() {
            this.processing = true;
        },
        stopProcessing() {
            this.processing = false;
        },
        startEventFormProcessing() {
            this.eventFormProcessing = true;
        },
        stopEventFormProcessing() {
            this.eventFormProcessing = false;
        },
        startDocumentFormProcessing() {
            this.documentFormProcessing = true;
        },
        stopDocumentFormProcessing() {
            this.documentFormProcessing = false;
        },
        ...mapActions({
            fetchAccueil: "fetchAccueil",
            fetchMembres: "fetchMembres",
            saveNewMember: "storeMembre",
            removeMember: "destroyMembre",
            updateMembre: "updateMembre",
            update: "updateAccueil",
            fetchEvents: "fetchEvents",
            saveNewEvent: "storeEvent",
            removeEvent: "destroyEvent",
            removeImage: "destroyEventImage",
            updateEvent: "updateEvent",
            fetchContacts: "fetchContacts",
            markContactAsRead : "markContactAsRead",
            fetchDocuments: "fetchDocuments",
            saveDocument: "storeDocument",
            updateDocument: "updateDocument",
            removeDocument: "destroyDocument",
        }),
    },
};
</script>
<style scoped>
.mb-2 {
    margin-bottom: 1rem;
}

.action_icons {
    margin: 4px;
    font-size: large;
}
</style>