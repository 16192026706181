var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-upload",
    {
      staticClass: "avatar-uploader",
      attrs: {
        action: _vm.action,
        headers: { Authorization: "Bearer " + _vm.bearer },
        multiple: false,
        showUploadList: false,
        listType: "picture-card",
        name: "file"
      },
      on: {
        change: function($event) {
          return _vm.handleUploadChange($event, _vm.record.id)
        }
      }
    },
    [
      _vm.showUploadedImg
        ? _c("img", {
            staticClass: "profile_pic",
            attrs: { src: _vm.imgUrl, alt: "avatar" }
          })
        : _c(
            "div",
            [
              _c("a-icon", {
                attrs: { type: _vm.loading ? "loading" : "plus" }
              }),
              _c("div", { staticClass: "ant-upload-text" }, [_vm._v("Joindre")])
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }