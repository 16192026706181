<template>
  <a-modal
      :title="title"
      :visible="visible"
      :width="750"
      @cancel="handleCancel"
      okText="Valider"
      @ok="handleOk"
      :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
        :layout="formLayout"
        id="event_form"
        name="event_form"
        :form="form"
    >
      <a-row>
        <a-col :span="24">
          <a-form-item v-bind="formItemLayout" label="Titre:">
            <a-input
                v-decorator="[
                'titre',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Titre est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
            <a-form-item v-bind="formItemLayout" label="Date:">       
                <a-date-picker
                    :format="dateFormat"
                    style="width:100%"
                   
                   
                     v-decorator="[
                        'dt_evenement',
                        {
                        rules: [
                            {
                            required: true,
                            message: 'Description est obligatoire!',
                            },
                        ],
                        },
                    ]"
                />
            </a-form-item>
        </a-col>
         <a-col :span="24">
          <a-form-item v-bind="formItemLayout" label="Description:">    
            <a-textarea
                v-decorator="[
                'description',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Description est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
         <a-col :span="24">
             <a-form-item label="Contenu:" v-bind="formItemLayout">
                 <a-textarea
                     v-decorator="[
                'contenu',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Contenu est obligatoire!',
                    },
                  ],
                },
              ]"
                 />
             </a-form-item>
         </a-col>
          <a-col :span="24">
          <a-form-item v-bind="formItemLayout" label="Visiblité">
            <a-select
                placeholder="Visiblité"
                v-decorator="[
                'etat',
                {
                  rules: [
                    { required: true, message: 'Visiblité  est obligatoire!' },
                  ],
                },
              ]"
            >
              <a-select-option :key="2">Afficher</a-select-option>
              <a-select-option :key="1">Masquer</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>          
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button
          key="submit"
          type="primary"
          :loading="processing"
          @click="handleOk"
      >Valider</a-button
      >
    </template>
  </a-modal>
</template>
<script>

// import {VueEditor} from "vue2-editor";

export default {
  name: "EventForm",
  created() {
    this.fillTheForm();
  },
  mounted() {
    this.fillTheForm();
  },
  // components: {
        // VueEditor
    // },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 4},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: { span: 18 },
        },
      },
      form: this.$form.createForm(this)
    };
  },
  props: {
    processing: {type: Boolean, default: () => false},
    visible: {type: Boolean, default: () => false},
    evenement: {type: Object},
  },
  computed: {
    title() {
      return ``;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("submit");
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    fillTheForm() {
      if (this.evenement) {
        this.$nextTick(() => this.form.setFieldsValue(this.evenement));
      }
    }
  },
};
</script>
