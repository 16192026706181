<template>
  <a-modal
      :title="title"
      :visible="visible"
      @cancel="handleCancel"
      okText="Valider"
      @ok="handleOk"
      :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
        :layout="formLayout"
        id="decision_form"
        name="decision_form"
        class="adherent_form"
        :form="form"
    >
      <a-row>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Nom complet:">
            <a-input
                v-decorator="[
                'full_name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Nom complet est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
         <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Grade:">
            <a-input
                v-decorator="[
                'grade',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Grade est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Visiblité">
            <a-select
                placeholder="Visiblité"
                v-decorator="[
                'etat',
                {
                  rules: [
                    { required: true, message: 'Visiblité  est obligatoire!' },
                  ],
                },
              ]"
            >
              <a-select-option :key="2">Afficher</a-select-option>
              <a-select-option :key="1">Masquer</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

      
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button
          key="submit"
          type="primary"
          :loading="processing"
          @click="handleOk"
      >Valider</a-button
      >
    </template>
  </a-modal>
</template>
<script>

export default {
  name: "MemberForm",
  created() {
    this.fillTheForm();
  },
  mounted() {
    this.fillTheForm();
  },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: { span: 14 },
        },
      },
      form: this.$form.createForm(this)
    };
  },
  props: {
    processing: {type: Boolean, default: () => false},
    visible: {type: Boolean, default: () => false},
    membre: {type: Object},
  },
  computed: {
    title() {
      return ``;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("submit");
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    fillTheForm() {
      if (this.membre) {
        this.$nextTick(() => this.form.setFieldsValue(this.membre));
      }
    }
  },
};
</script>
