<template>
    <a-upload
        :action="action"
        :headers="{ Authorization: `Bearer ${bearer}` }"
        :multiple="false"
        :showUploadList="false"
        class="avatar-uploader"
        listType="picture-card"
        name="file"
        @change="handleUploadChange($event, record.id)"
    >
        <img
            v-if="showUploadedImg"
            :src="imgUrl"
            alt="avatar"
            class="profile_pic"
        />
        <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">Joindre</div>
        </div>
    </a-upload>
</template>

<script>

export default {
    name: "Uploader",
    props: {
        action: {type: String, default: () => null},
        bearer: {type: String, default: () => null},
        record: {type: Object},
        isAdmin: {type: Boolean, default: () => false},
        imgUrl: {type: String, default: () => null},
        showUploadedImg: {type: Boolean, default: () => false},
    },

    methods: {
        handleUploadChange(info, id) {
            if (info.file.status === "done") {
                this.$_showSuccessMessage(
                    `${info.file.name} a été importé avec succès`
                );
                this.$emit("upload_success");
            }
            if (info.file.status === "removed") {
                this.$emit("delete_file", id);
                this.$_showSuccessMessage(
                    `${info.file.name} a été supprimé avec succès`
                );
            }
            if (info.file.status === "error") {
                this.$_throwAnError(info.file.response.errors.file);
            }
        },
    },
};
</script>

<style scoped>
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.profile_pic{
    width: 100%;
    margin-right: 10px;
    height: auto;
}
</style>
