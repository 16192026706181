<template>
  <a-upload
    name="file"
    :action="action"
    :headers="{ Authorization: `Bearer ${bearer}` }"
    @change="handleUploadChange($event, record.id)"
  >
    <a-button class="ant-btn-primary">
      <a-icon type="upload" />
      Joindre
    </a-button>
  </a-upload>
</template>

<script>
// import head from "lodash-es/head";

export default {
  name: "Uploader",
  props: {
    action: { type: String, default: () => null },
    bearer: { type: String, default: () => null },
    record: { type: Object },
    isAdmin: { type: Boolean, default: () => false },
  },

  methods: {
    handleUploadChange(info, id) {
      if (info.file.status === "done") {
        this.$_showSuccessMessage(
          `${info.file.name} a été importé avec succès`
        );
        this.$emit("upload_success");
      }
      if (info.file.status === "removed") {
        this.$emit("delete_file", id);
        this.$_showSuccessMessage(
          `${info.file.name} a été supprimé avec succès`
        );
      }
      if (info.file.status === "error") {
        this.$_throwAnError(info.file.response.errors.file);
      }
    },
  },
};
</script>

<style scoped></style>
