var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-upload",
    {
      attrs: {
        name: "file",
        action: _vm.action,
        headers: { Authorization: "Bearer " + _vm.bearer }
      },
      on: {
        change: function($event) {
          return _vm.handleUploadChange($event, _vm.record.id)
        }
      }
    },
    [
      _c(
        "a-button",
        { staticClass: "ant-btn-primary" },
        [
          _c("a-icon", { attrs: { type: "upload" } }),
          _vm._v("\n    Joindre\n  ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }